<template>
  <v-container>
    <v-card class="ml-5 mt-15">
      <v-data-table
        :headers="dessertHeaders"
        :items="orders"
        :search="searchpending"
        :loading="loading"
        loading-text="Please wait..."
        item-key="id"
        class="elevation-1"
        style="background: #f5f5f5"
      >
        <template v-slot:top>
          <v-toolbar elevation="2" style="background: #f5f5f5; font-size: 26px">
            <v-spacer></v-spacer>
            <div class="pr-5 pb-5 pt-2">
              <v-text-field
                v-model="searchpending"
                append-icon="mdi-magnify"
                label="Search"
                filled
                rounded
                dense
                hide-details
              ></v-text-field>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            style="padding-left: 0px !important"
            text
            @click="Approve(item)"
            >Approve</v-btn
          >
        </template>

        <template v-slot:item.dateaction="{ item }">
          <p class="mb-0">
            {{ new Date(item.date).toDateString() }}
          </p>
        </template>

        <template v-slot:item.leng="{ item }">
          <p class="mb-0">
            {{ item.transaction_ids.length }}
          </p>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>


<script>
export default {
  props: ["id"],
  data: () => ({
    searchpending: "",
    loading: true,
    dessertHeaders: [
      {
        text: "Chalan ID",
        align: "start",
        value: "id",
        sortable: false,
      },

      { text: "Date", value: "dateaction", sortable: false },
      { text: " Status", value: "chalan_status", sortable: false },
      { text: "Items", value: "leng", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],

    orders: [],
    show: false,
  }),

  methods: {
    initialize() {
      axios
        .get(`manager/show_specific_manager_pending_group_chalan/1/${this.id}/`)
        .then((response) => {
          this.orders = response.data.data;
          this.loading = false;
        });
    },
    Approve(item) {
      let routeData = this.$router.resolve({
        name: `/managerdashboard/pages/group-products/warehouse/chalan_approval/${item.id}`,
      });
      routeData.href = `/managerdashboard/pages/group-products/warehouse/chalan_approval/?id=${item.id}`;
      window.open(routeData.href, "_blank");
    },
  },
  created() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>